<template>
    <v-card>
      <v-toolbar>
        <v-btn icon @click="goBack"><v-icon>mdi-chevron-left</v-icon></v-btn>
      </v-toolbar>
      <v-card-text>
        <applicant-settings
          :applicantId="applicantId"
          :applicantData="applicantData"
          :ownerData="ownerData"
          :loading="loading"
        />
      </v-card-text>
    </v-card>
</template>

<script>
import { mapState, createNamespacedHelpers } from 'vuex'
import { getOwner, getResponse } from '../helpers/firebase-functions'
import ApplicantSettings from '@/components/botSettings/applicantSettings'
const { mapActions: mapActionsCalendar } = createNamespacedHelpers('calendar')

export default {
  components: {
    ApplicantSettings
  },
  data: () => ({
    applicantId: null,
    applicantData: null,
    ownerData: null
  }),
  computed: {
    ...mapState('auth', ['uid']),
    loading () {
      return this.applicantId === null || this.applicantData === null || this.ownerData === null
    }
  },
  methods: {
    ...mapActionsCalendar(['setupdateRescheduleConfirmation', 'authenticateSchedulerlyClient']),
    goBack () {
      this.$router.back()
    }
  },
  async mounted () {
    await this.authenticateSchedulerlyClient()
    this.setupdateRescheduleConfirmation(true)

    const ownerId = this.uid
    const scenarioId = process.env.VUE_APP_RECRUIT_SCENARIO_ID
    this.ownerData = await getOwner({ ownerId, scenarioId })
    this.applicantId = this.$route.params.applicantId
    this.applicantData = await getResponse({ ownerId, scenarioId, responseId: this.applicantId })
  },
  destroyed () {
    this.setupdateRescheduleConfirmation(false)
  }
}
</script>
