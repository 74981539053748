<template>
  <div>
    <v-progress-linear v-if="loading" indeterminate />
    <LoadingDialog v-if="!upil" />
    <BotSettings
      v-if="upil"
      :upil="upil"
      :title="$t('m.applicant.details.title')"
      :suppressMissingValueMessage="suppressMissingValueMessage"
    />
    <Popup
      v-bind="showApplicantRescheduledNoticePropData"
      @confirm="showApplicanatRescheduledNotice = false"
      @update:showDialog="showApplicanatRescheduledNotice = false"
    />
    <Popup
      v-bind="showDeletePreviousSlotPropData"
      @confirm="removePreviousSlot"
      @cancel="showDeletePreviousSlot = false"
      @update:showDialog="showDeletePreviousSlot = false"
    />
  </div>
</template>

<script>
import { UPILCore } from '@appsocially/userpil-core'
import { parseDateTimeString } from '@appsocially/schedulerly-client/src/helpers/time'
import BotSettings from '@/components/botSettings'
import LoadingDialog from '@/components/LoadingDialog'

import applyScript from '@/upil-scripts/apply'
import { listeners } from '@/listeners/apply'
import { mapState, mapActions } from 'vuex'
import { firestore } from '@/helpers/firestore'
import { COLLECTIONS_ENUM } from '@/enums'
import Popup from '@/components/calendar/Popup'
import { withSentry } from '../../helpers/sentry'

function setupListeners ({ listeners, upil }) {
  const unsubscribeArray = Object.keys(listeners).map(event => {
    const handler = listeners[event]
    return upil.on(event, handler)
  })

  return unsubscribeArray
}

export default {
  name: 'ApplicantSettings',
  components: {
    BotSettings,
    LoadingDialog,
    Popup
  },
  props: {
    /**
     * The response document of the applicant
     */
    applicantData: {
      type: Object
    },
    /**
     * The UID of the applicant
     */
    applicantId: {
      type: String
    },
    /**
     * The public scenario document for the owner
     */
    ownerData: {
      type: Object
    },
    loading: {
      type: Boolean,
      default: false
    },
    listeners: {
      type: Object,
      default: () => ({})
    },
    suppressMissingValueMessage: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      upil: null,
      showDeletePreviousSlot: false,
      showApplicanatRescheduledNotice: false,
      schedulerlySlotId: '',
      previousSchedulerlySlotId: ''
    }
  },
  computed: {
    ...mapState('auth', ['uid']),
    ...mapState('calendar', ['savedInterviewId']),
    ready () {
      return this.uid && this.loading === false
    },
    showApplicantRescheduledNoticePropData () {
      return {
        showDialog: this.showApplicanatRescheduledNotice,
        title: '応募者様へ日時変更の依頼をお送りしました。',
        confirm: 'はい'
      }
    },
    showDeletePreviousSlotPropData () {
      return {
        showDialog: this.showDeletePreviousSlot,
        title: '変更前の日時はご都合がよろしくないようですね。面接枠を削除しておきますか？',
        confirm: 'はい',
        cancel: 'いいえ'
      }
    },
    interviewRef () {
      return firestore
        .collection(COLLECTIONS_ENUM.OWNERS)
        .doc(this.uid)
        .collection(COLLECTIONS_ENUM.SCENARIO_PUBLIC)
        .doc(process.env.VUE_APP_RECRUIT_SCENARIO_ID)
        .collection(COLLECTIONS_ENUM.INTERVIEWS)
    }
  },
  watch: {
    ready: {
      immediate: true,
      handler (ready) {
        if (ready) {
          this.start()
        }
      }
    }
  },
  methods: {
    ...mapActions('calendar', ['getCalendars', 'setCalendarSubscriptionInfo', 'setupdateShowProposedDate', 'removeSlot']),
    async start () {
      this.setupdateShowProposedDate(false)

      await this.setCalendarSubscriptionInfo({ ownerId: this.uid, scenarioId: process.env.VUE_APP_RECRUIT_SCENARIO_ID })
      const upil = new UPILCore()

      const { jobOpeningTypeListJa, ...ownerData } = this.ownerData

      const scriptData = {
        applicantId: this.applicantId,
        isActive: 'ON',
        ...this.applicantData,
        ...ownerData,
        jobOpeningTypeListJa
      }

      const external = async (payload, preventDefault) => {
        preventDefault()
        const { topic } = payload
        switch (topic) {
          // For backwards compatibility with old string data model
          case 'usesOldJobOpeningType':
            return !jobOpeningTypeListJa
          default:
            return upil.symbols.UNRESOLVED
        }
      }

      // Setup formode listeners
      setupListeners({ listeners: { 'preload-input': async () => scriptData, external }, upil })

      // Setup original listeners
      const { 'input-update': inputUpdate } = listeners(this.uid, this.applicantId)
      setupListeners({ listeners: { 'input-update': inputUpdate }, upil })

      // Setup applicant setting listeners
      setupListeners({ listeners: {
        'input-update': async (payload) => {
          if (payload.event && payload.event.node.input) {
            const { event: { node: { input: { name } } } } = payload
            if (name === 'isoDate') {
              const { isRescheduledInterview, schedulerlySlotId } = await this.getInterviewData()

              this.previousSchedulerlySlotId = this.schedulerlySlotId
              this.schedulerlySlotId = schedulerlySlotId
              this.showDeletePreviousSlot = isRescheduledInterview

              if (isRescheduledInterview) {
                this.showApplicanatRescheduledNotice = true
              // await withSentry(
              //   notifyApplicantReschedule({
              //     applicantId: this.applicantId,
              //     savedInterviewId: this.savedInterviewId })
              // )
              }
            }
          }
        }
      },
      upil })

      // Setup parent listeners (come before default listeners)
      setupListeners({ listeners: this.listeners, upil })
      upil.startRaw(applyScript, {
        mode: 'form',
        resetOnInputUpdate: true,
        dependencyResolution: 'resolve-undefined'
      })
      this.upil = upil
      const interview = await withSentry(this.getInterviewActiveInterview())
      if (interview) {
        const { schedulerlySlotId } = interview
        this.schedulerlySlotId = schedulerlySlotId
      }
    },
    goBack () {
      this.$router.back()
    },
    async getInterviewData () {
      if (!this.savedInterviewId) {
        return {}
      }
      const data = await await this.interviewRef
        .doc(this.savedInterviewId)
        .get().then(doc => doc.data())

      return data
    },
    async getInterviewActiveInterview () {
      const activeInterviews = await this.interviewRef
        .where('state', '==', 'ACTIVE')
        .where('applicantId', '==', this.applicantId)
        .get()
        .then(snapshot => snapshot.docs.map(doc => doc.data()))

      return activeInterviews[0]
    },
    async removePreviousSlot () {
      const getStartDateTime = async schedulerlySlotId => {
        const interviews = await this.interviewRef
          .where('schedulerlySlotId', '==', schedulerlySlotId)
          .get()
        const { isoDate, startTime } = interviews.docs[0].data()
        const startDateTime = parseDateTimeString(`${isoDate} ${startTime}`)
        return startDateTime
      }

      this.showDeletePreviousSlot = false
      // const calendars = await schedulerService.getCalendars(this.uid)
      // const { id } = calendars[0]
      // await schedulerService.deleteCalendarSlot(id, this.previousSchedulerlySlotId)

      await this.getCalendars(this.uid)
      this.removeSlot({
        id: this.previousSchedulerlySlotId,
        startDate: await getStartDateTime(this.previousSchedulerlySlotId)
      })
    }
  }
}
</script>

<style scoped>
.title-column {
  min-width: 100px;
  text-align: right;
}
</style>
